<template>
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <section class="invoice-add-wrapper">
        <b-form ref="formBudget">
          <input type="hidden" name="id" :value="budget_id">
          <input type="hidden" name="status" :value="budget.status">
          <input type="hidden" name="consent_signature" :value="consent_signature">
          <b-row class="invoice-add">
            <b-col cols="12" xl="9" md="8">
              <fieldset :disabled="budget.status != 0">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row v-for="item,index in budget.lines" :key="index" ref="row" class="pb-2">
                      <input type="hidden" :name="'lines['+ index +'][id]'" :value="item.id">
                      <b-col cols="12">
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <b-col cols="12" lg="3"> Concepto </b-col>
                            <b-col cols="12" lg="3"> Unidades</b-col>
                            <b-col cols="12" lg="2"> Precio </b-col>
                            <b-col cols="12" lg="2" v-if="checked != 0"> Descuento %</b-col>
                            <b-col cols="12" lg="2"><strong> Base</strong> </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>
                          
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <b-col cols="12" lg="3">
                              <label class="d-inline d-lg-none">Concepto</label>
                              <!-- <b-form-input :name="'lines['+index+'][concept]'" v-model="budget.lines[index].concept" class="mb-1 mb-sm-0" required/> -->
                              <b-form-textarea maxlength="3000" required :name="'lines['+index+'][concept]'" v-model="budget.lines[index].concept" class="mb-1 mb-sm-0" />
                            </b-col>
                            <b-col cols="12" lg="3">
                              <label class="d-inline d-lg-none">Unidades</label>
                              <b-form-input :name="'lines['+index+'][qty]'" type="number" required v-model="budget.lines[index].qty"  class="mb-1 mb-sm-0"  @change="recalc(index)" />
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Precio</label>
                              <b-form-input :name="'lines['+index+'][punit]'" type="number" class="mb-1 mb-sm-0" v-model="budget.lines[index].punit" @change="recalc(index)" />
                            </b-col>
                            <b-col cols="12" lg="2" v-if="checked == 1">
                              <label class="d-inline d-lg-none">Descuento %</label>
                              <b-form-input v-if="checked" :name="'lines['+index+'][pdiscount]'" type="number" class="mb-1 mb-sm-0" v-model="budget.lines[index].pdiscount" @change="recalc(index)" />                              
                            </b-col>                            
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Base</label>
                              <input type="hidden" :name="'lines['+index+'][ptotal]'" v-model="budget.lines[index].ptotal">
                              <p class="mb-1 text-center"> {{ checked ? budget.lines[index].ptotal : budget.lines[index].ptotal - budget.lines[index].pdiscount}} € </p>
                            </b-col>
                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon size="16" icon="TrashIcon" class="cursor-pointer" @click="removeItem(index)" v-if="budget.status == 0" />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
                <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item" v-if="checked != '0'">
                          <p class="invoice-total-title">Descuento %: </p>
                          <p class="invoice-total-amount"> - {{budget.discount}} €</p>
                          <input type="hidden" name="discount" v-model="budget.discount">
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Base: </p>
                          <p class="invoice-total-amount"> {{budget.subtotal}} €</p>
                          <input type="hidden" name="subtotal" v-model="budget.subtotal">
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title"> IVA (21%): </p>
                          <p class="invoice-total-amount"> {{budget.iva}} € </p>
                          <input type="hidden" name="iva" v-model="budget.iva">
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title"> Total: </p>
                          <p class="invoice-total-amount"> {{budget.total}} € </p>
                          <input type="hidden" name="total" v-model="budget.total">
                        </div>
                      </div>
                    </b-col>
                    <b-button type="button" class="mb-2 ml-2" size="sm" variant="primary" @click="addNewItemInItemForm"> Añadir línea + </b-button>
                  </b-row>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Note -->
                <b-card-body class="invoice-padding pt-0">
                  <div class="fs-1 pb-2">
                    <h4>Descuentos:</h4>                     
                    <b-form-checkbox v-model="checked" name="with_discount" switch @change="updateTotals"  value="1"  unchecked-value="0" >
                      <strong>{{ checked == 1 ? 'Sí' : 'No' }}</strong>
                    </b-form-checkbox>
                  </div>
                  <span class="font-weight-bold">Descripción (visible en presupuesto): </span>
                  <b-form-textarea maxlength="255" name="description" v-model="budget.description" />
                </b-card-body>
              </b-card>
            </fieldset>
            </b-col>
            <!-- Right Col: Card -->
            <b-col cols="12" md="4" xl="3" class="invoice-actions">
              <b-card class="client-card">
                <span>{{budget.doc_num}} 
                  <b-badge pill :variant="status[budget.status].class" class="ml-3" v-if="!show">{{status[budget.status].text}}</b-badge>
                </span>
                <hr> 
                <div v-if="budget.work_orders_client">
                  <h5 class="mb-25">Datos Cliente: 
                    <!-- <b-button variant="success" class="ml-1 btn-sm" @click="editClient(budget.work_orders_client.id)">Editar</b-button> -->
                  </h5>  
                  <hr>
                  <h5>{{ budget.work_orders_client.client.name}}</h5>
                  <p>NIF/CIF: {{ budget.work_orders_client.client.nif}}</p>
                  <p>Tel: {{ budget.work_orders_client.client.phone}}</p>
                  <p>Email: {{ budget.work_orders_client.client.email}}</p>
                  <hr>
                  <h5> <strong>Dirección Actuación</strong></h5>
                  <p>{{ budget.work_orders.cp_full_address}}</p>
                </div> 
                <hr>
                <label> Vigencia (meses) : </label>
                <b-form-input name="validity" v-model="budget.validity" class="mb-2" />
                <hr>                
                <b-button style="text-align: left" variant="success" block @click="updateBudget" :disabled="budget.status != 0">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                  </svg>&nbsp;
                  Guardar Cambios
                </b-button>
                <b-button style="text-align: left" variant="warning" block @click="createDelivery" v-if="budget.status == 1">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                  </svg>&nbsp;
                  Crear albarán
                </b-button>
                <b-button :to="'/create-budget/' + budget.work_order_id" style="text-align: left" variant="warning" block v-if="budget.status == 0">
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>&nbsp;
                  Crear otro presupuesto
                </b-button>
                <b-button style="text-align: left" variant="warning" block v-if="budget.status == 0" v-b-modal.modal-signature>
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                    <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                  </svg>&nbsp;
                  Aceptar presupuesto
                </b-button>
                <b-button style="text-align: left" variant="danger" block v-if="budget.status == 1 && !budget.work_orders.has_delivery" @click="reOpen()" >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" clip-rule="evenodd" />
                  </svg>&nbsp;
                  Editar presupuesto
                </b-button>
                <p v-if="budget.status == 1 && !budget.work_orders.has_delivery" style="color:#777777; padding-left:5px; font-style: italic;">Este presupuesto contiene la firma del cliente conforme, si lo editas el cliente debe de volver a firmar el presupuesto como conforme con el nuevo presupuesto.</p>
                <br>
                <b-button class="btn-blue" style="text-align: left" variant="success" block @click="print()"  >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm5.845 17.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clip-rule="evenodd" />
                    <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                  </svg>&nbsp;
                  Descargar
                </b-button>
                <b-button class="btn-blue" style="text-align: left" variant="success" block @click="sendEmail()" :disabled="!isEmailable" >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>&nbsp;
                  <span v-if="this.budget.emailed != 1">Enviar Email</span> 
                  <span v-else>Volver a enviar email</span>
                </b-button>
                <br>
                <b-button class="btn-blue" style="text-align: left" variant="success" :href="'/work-order/'+budget.work_order_id" block >
                  <svg style="width: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fill-rule="evenodd" d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                  Volver a la Orden sin guardar
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </section>
      <b-modal id="edit-client-modal" title="Editar datos cliente">
        <label class="mt-1">Nombre Fiscal</label><b-form-input type="text" name="name" v-model="client.name"></b-form-input>
        <label class="mt-1">NIF</label><b-form-input type="text" v-model="client.nif"></b-form-input>       
        <label class="mt-1">Persona contacto</label><b-form-input type="text" v-model="client.contact"></b-form-input>       
        <label class="mt-1">Teléfono contacto</label><b-form-input type="text" v-model="client.phone_aux"></b-form-input>       
        <label class="mt-1">Teléfono</label><b-form-input type="text" v-model="client.phone"></b-form-input>       
        <label class="mt-1">Email</label><b-form-input type="text" v-model="client.email"></b-form-input>       
        <label class="mt-1">Dirección</label><b-form-input type="text" v-model="client.address1" ></b-form-input>
        <label class="mt-1">Piso</label><b-form-input type="text" v-model="client.address2" ></b-form-input>
        <label class="mt-1">Población</label>
        <v-select
          v-model="optionsLocationSelected"
          @search="onSearchLocation"
          :options="optionsLocation"
          label="name"
         />        
        <!-- <label class="mt-1">Población</label><b-form-input type="text" v-model="client.cp_municipality_id"></b-form-input> -->
        <label class="mt-1">Código postal</label><b-form-input type="text" v-model="client.cp"></b-form-input>       
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="saveClient">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>      
      <b-modal id="modal-signature" title="Aceptar presupuesto" >
          <b-card>
            <b-card-body>
                    <VueSignature
                      id="signature"
                      width="100%"
                      height="300px"
                      ref="signaturePad"
                      :options="options"
                      :scaleToDevicePixelRatio=false
                    />
                  </b-card-body>
          </b-card>
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="save_signature">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-danger btn-sm btn-block mb-1" @click="undo_signature">Borrar firma</button>
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import VueSignature from "vue-signature-pad";
import vSelect from 'vue-select'
import { VBModal,BRow, BCol, BCard, BBadge, BOverlay, BCardBody, BLink, BListGroup, BListGroupItem, BButton, BFormInput, BInputGroup, BForm, BFormTextarea, BFormFile, BFormCheckbox} from "bootstrap-vue";
export default {
  components: {vSelect,VBModal, VueSignature, BRow, BCol, BCard, BBadge, BCardBody, BLink, BButton, BListGroup, BListGroupItem, BOverlay, BFormInput, BInputGroup, BForm, BFormTextarea, BFormFile,BFormCheckbox},
  data() {
    return {  
      checked: false,
      consent_signature : null,    
      show: true,
      order_id : null, // TODO: BORRAR
      budget_id : this.$route.params.budget,
      client : {name : '', address1 : '', location : '', cp : '', phone: '', index : -1 },
      optionsLocation: [],
      optionsLocationSelected: null,
      budget : { lines : {}},
      status : [
        {text :'nuevo', class : 'secondary'},
        {text :'aceptado', class : 'success'},
        {text :'rechazado', class : 'danger'},
       ],
       options: {
        penColor: "#333",
        onBegin: () => {this.$refs.signaturePad.resizeCanvas()}
      },
    };
  },
  created() {
    this.$http.get("/api/getBudget?id=" + this.budget_id).then((response) => {      
      this.budget = response.data;
      this.show = false   
      this.checked = this.budget.with_discount;
      if(!this.budget.lines.length) {
        this.addNewItemInItemForm();      
      }
    })        
    
  },
  methods: {
    onSearchLocation(search, loading){
      loading(true);
      this.$http.get(`/api/getLocation?q=${search}`).then((response) => {
        this.optionsLocation = response.data
        loading(false);
      });
    },
    // *************** Validation
    validate () {
      for (let i = 0; i < this.budget.lines.length; i++) {
        if(!this.budget.lines[i].concept || this.budget.lines[i].punit === ""|| this.budget.lines[i].qty === ""){
          return false
        } 
      }
      return true;
    },
    // *************** Calc functions
    format_import(value) {
      return (Math.round((value + Number.EPSILON) * 100) /100).toFixed(2);
    },
    recalc(index){
      let sum = 0;
      let ptotal = 0; let iva = 0 ; let total = 0; let discount = 0;
      
      if(index >= 0) {
        ptotal =  (this.budget.lines[index].punit * this.budget.lines[index].qty) - (((this.budget.lines[index].punit * this.budget.lines[index].qty) * this.budget.lines[index].pdiscount) / 100);
        this.budget.lines[index].ptotal = this.format_import(ptotal)
        this.$forceUpdate();
        for (let i = 0; i < this.budget.lines.length; i++) {
          sum += parseFloat(this.budget.lines[i].ptotal);
          discount += ((this.budget.lines[i].punit * this.budget.lines[i].qty) * (this.budget.lines[i].pdiscount) / 100)
        }
      }else{
        for (let i = 0; i < this.budget.lines.length; i++) {
          ptotal = 0
          ptotal =  (this.budget.lines[i].punit * this.budget.lines[i].qty) - (((this.budget.lines[i].punit * this.budget.lines[i].qty) * this.budget.lines[i].pdiscount) / 100);
          this.budget.lines[i].ptotal = this.format_import(ptotal)          
          sum += parseFloat(this.budget.lines[i].ptotal);
          discount += ((this.budget.lines[i].punit * this.budget.lines[i].qty) * (this.budget.lines[i].pdiscount) / 100)
        }
      }
      this.budget.subtotal = this.format_import(sum);
      iva = sum * 0.21;
      this.budget.iva = this.format_import(iva);
      total =  sum + iva;
      this.budget.discount = this.format_import(discount);
      this.budget.total = this.format_import(total);

    },
    
    updateTotals(){
      if(this.checked == 0) {
        for (let i = 0; i < this.budget.lines.length; i++) {
          this.budget.lines[i].pdiscount = 0.00;
        }
        this.recalc(-1);
      }
    },
    updateBudget : async function (e) {
      if(!this.validate()){
        this.$bvToast.toast('ERROR : No pueden existir linias sin concepto, unidades o precio', { variant: 'danger',noCloseButton : true });
        return
      }
      this.show = true;
      var formData = new FormData(this.$refs.formBudget);
      if(this.checked == 0){
        formData.set('with_discount', 0);
      } else {
        formData.set('with_discount', 1);
      }
      await this.$http({
        method: "post",
        url: "/api/updateBudget",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { 
        this.show = false;
        this.budget = response.data;
        this.$bvToast.toast('Cambios guardados con exito', { variant: 'success',noCloseButton : true });
      }).catch(response => { 
          window.console.log(response);
          this.show = false;
          this.$bvToast.toast('Error al guardar los datos', { variant: 'danger',noCloseButton : false });
      });
      
    },
    reOpen () {
      this.$bvModal.msgBoxConfirm('¿Desea reabrir el presupuesto? Deberá volver a pedir la aceptación del cliente.', {size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
          this.$http.post("/api/updateBudgetStatus",{ id :  this.budget_id , status : 0}).then((response) => {
            this.budget.status = 0;
          })  
        }
      })
    },
    // *************** CRUD LINES
    removeItem (index) {
      this.$bvModal.msgBoxConfirm('¿Desea borrar esta linia?', {size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
          if (this.budget.lines[index].id) {
            this.show   = true;
            this.$http.post("/api/removeLineBudget",{id : this.budget.lines[index].id }).then((response) => {
              if (response.data) {
                this.show = false;
                this.$bvToast.toast('Linia eliminada con exito', { variant: 'success',noCloseButton : true });
                this.budget.lines.splice(index, 1)
                //this.recalc(index);
                this.recalc(-1);
                setTimeout(() => {
                  this.updateBudget();
                }, 100);
              }else {
                alert('Los datos no han podido ser almacenados')
              }
           });                       
          }else {
            this.budget.lines.splice(index, 1)
          }
        }
        
      })
    },
    addNewItemInItemForm() {
      this.budget.lines.push({concept : "",  qty : 1.00, punit : 0.00, pdiscount : 0.00, ptotal: 0.00 })
    },
    // ********************** Create Albaran
    createDelivery() {
      this.show = true;
      this.$http.post("/api/insertDelivery",{id : this.budget.work_order_id, budget_id : this.budget.id}).then((response) => {
              if (response.data) {
                this.show = false;
                this.$router.push('/delivery/'+ response.data)
              }else {
                alert('No se ha podido crear el Albarán')
              }
           });    
    },
    // ********************** firma 
    async save_signature() {
      await this.updateBudget();
      let that = this;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        return alert('La firma es necesaria.');
      }
      let imagen = data.replace(/^data:image\/(png|jpg);base64,/, "");
      this.consent_signature = imagen;      
      this.$bvModal.hide('modal-signature')  
      this.budget.status = 1;    
      setTimeout(function() {
        that.updateBudget();
      }, 100);       
      // guardar firma y budget con status actualizado en Backend
    },
    undo_signature() {
      this.$refs.signaturePad.undoSignature();
    },    
    //CLIENTE
    editClient(index) {  
      this.client = this.budget.work_orders_client;
      this.optionsLocation =  this.client.location ? [{name : this.client.location.name, id : this.client.location.id}] : [{}];
      this.optionsLocationSelected = this.client.location
      this.$bvModal.show('edit-client-modal')
    },
    // NO UTILIZADO DUPLICADO ¿?
    // validate () {
    //   for (let i = 0; i < this.client.length; i++) {
    //     if(!this.client[i] == ""){
    //       return false
    //     } 
    //   }
    //   return true;
    // },
    saveClient : async function (e) {   
      if (!this.optionsLocationSelected) {
        this.$bvToast.toast('ERROR : Población es un campo obligatorio', { variant: 'danger',noCloseButton : true });
      }
      this.client.cp_municipality_id = this.optionsLocationSelected.id
      let that = this
      this.$http.post("/api/updateClient",this.client).then((response) => {
        if (response.data) {
          that.order.client = that.client = response.data
          that.$forceUpdate();
        }else {
          alert('Los datos no han podido ser almacenados')
        }
        this.$bvModal.hide('edit-client-modal')
        });      
    },
    // ******************************** Print & SEND
    print() {
      this.$http({
        url: "/api/printBudget/" + this.budget_id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if(response.status == 200){
          var fileURL = window.URL.createObjectURL(new Blob([response.data], {type:'application/pdf'}));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'presupuesto.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
    },
    sendEmail() {
      this.show = true;
      this.$http.get("/api/sendBudgetMail/" + this.budget_id, {timeout: 25000}).then((response) => {
        this.budget.emailed = 1;
        this.show = false;
        this.$bvToast.toast('Presupuesto enviado con éxito,', { variant: 'success',noCloseButton : true });
      }).catch((e) => {
        this.show = false;
        this.$bvToast.toast('Error al enviar el presupuesto, asegurate que el cliente tiene email introducido', { variant: 'danger',noCloseButton : false });
      });  
    }
   },
   computed: {
    isEmailable: function () {
     return this.budget.work_orders_client && this.budget.work_orders_client.client.email !== null && this.budget.work_orders_client.client.email != '';
    }
  },
};
</script>

<style lang="scss" >
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.client-card p {
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}
.btn-blue {
  background-color: #007bff!important;
  border-color: #007bff !important;
  color: white;
}
.btn-blue:hover {
  background-color: #0056b3;
  color: white;
  box-shadow: 0 8px 25px -8px #0056b3!important;
}
</style>